/* eslint-disable max-lines */
import React, { useState, useEffect, useContext } from 'react';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import licenseCount from './Services/GetLicenseData';
import {
  fetchAllInvitedUsers,
} from './Services/InviteTabService';
import TableComponent from '../../Shared/Table/TableComponent';
import MoreInfo from '../MoreInfo';
import AddUser from './Popups/AddUser';
import ImportedUserProgress from './Popups/ImportedUserProgress';
import BuyMoreLicense from './Popups/BuyMoreLicense';
import ConfirmPopup from './Popups/ConfirmPopup';
import * as invitesTabStyles from '../../../styles/admin/InviteTab.module.scss';
import AdminContext, { MoreInfoStatus } from '../../../context/Admin/AdminContext';
import LicenseInfo from './LicenseInfo';
import {
  postEvent, toaster, getAppVersion, getHeaders, hasInviteLimits,
} from '../../../services/utils';
import warningIcon from '../../../../static/images/admin/warning.svg';

function InviteTabView(props) {
  const { identityProviderClient, showInviteUserModal, setShowInviteUserModal } = props;
  const {
    profileInfo, companyInfo, showMoreInfo, setShowMoreInfo,
  } = useContext(AdminContext);
  const [tableData, setTableData] = useState([]);
  const [licenseCountObject, setLicenseCountObject] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [tableLoader, setTableLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImportStatusModalVisible, setIsImportStatusModalVisible] = useState(false);
  const [fileDataArray, setFileDataArray] = useState([]);
  const [licenseExceeded, setLicenseExceeded] = useState(false);

  /**
   * sort data based on invitedOn
   *
   * @param {*} data
   * @return {*}
   */
  const sortInvitedUsers = (data) => {
    const sortedData = data.sort(
      (a, b) => new Date(b.invitedOn) - new Date(a.invitedOn),
    );
    return sortedData;
  };

  const fetchSignedInUserDetails = async () => {
    setTableLoader(true);
    if (profileInfo?.id) {
      const { pendingInvitations } = await fetchAllInvitedUsers(profileInfo?.enterpriseID);
      const sortedData = sortInvitedUsers(pendingInvitations);
      setTableData(sortedData);
    }
    setTableLoader(false);
  };

  /**
   * get the license count
   *
   */
  const licenseCountHandler = async () => {
    const licenseCountResponse = await licenseCount();
    if (licenseCountResponse) {
      setLicenseCountObject(licenseCountResponse);
    }
  };

  const triggerEvent = async (response) => {
    try {
      const eventApiName = 'enterpriseappevents';
      const eventPath = '/events';
      const headers = await getHeaders();
      const eventPromises = response.map((data) => {
        const payload = {
          event: 'Invite Sent',
          context: {
            app: {
              version: getAppVersion(),
            },
          },
          id: uuidv4(),
          userId: profileInfo?.id,
          enterpriseId: profileInfo?.enterpriseID,
          originalTimestamp: dayjs().toISOString(),
          sentAt: dayjs().toISOString(),
          properties: {
            invitationId: data?.id,
            accountName: companyInfo?.name,
          },
        };
        return API.post(eventApiName, eventPath, {
          headers,
          body: payload,
        });
      });
      await Promise.all(eventPromises);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTableData = async () => {
    try {
      const { pendingInvitations } = await fetchAllInvitedUsers(companyInfo?.id);
      await licenseCountHandler();
      const sortedData = sortInvitedUsers(pendingInvitations);
      setTableData(sortedData);
    } catch (error) {
      toaster('Something went wrong', 'error');
    }
  };

  /**
   * send invite
   *
   * @param {*} selectedEmails
   */
  const sendInvite = async (selectedEmails) => {
    try {
      if (selectedEmails?.length) {
        setTableLoader(true);
        const apiName = 'enterpriseappinviteuser';
        const path = '/invite';
        const headers = await getHeaders();
        const promises = selectedEmails.map((email) => (
          API.post(apiName, path, {
            headers,
            body: {
              email: email.trim(),
              status: 'REQUESTED',
              inviter_id: profileInfo?.id,
              inviter_enterprise_id: companyInfo?.id,
            },
          })
        ));
        const responses = await Promise.allSettled(promises);

        const succeededApiCalls = [];
        let inviteAlreadyExists = false;
        responses.forEach((response) => {
          if (response.status === 'fulfilled') {
            succeededApiCalls.push(response.value);
          } else if (response.reason.request.status === 500) {
            inviteAlreadyExists = true;
          }
        });

        if (inviteAlreadyExists) {
          toaster('Invite already exists', 'error');
        } else if (responses.length !== succeededApiCalls.length) {
          toaster('Error in sending invite', 'error');
        } else {
          toaster('Invite sent successfully');
        }

        await updateTableData();
        triggerEvent(succeededApiCalls);
      }
    } catch (error) {
      toaster('Something went wrong', 'error');
    }
    setTableLoader(false);
  };

  /**
   * resend the invite
   *
   * @param {*} record
   */
  const resendInvites = async (record) => {
    if (!record) {
      toaster('Something went wrong', 'error');
      return;
    }
    setTableLoader(true);
    try {
      const apiName = 'enterpriseappinviteuser';
      const headers = await getHeaders();
      const path = `/invite/${record.key}/resend-invite`;
      await API.post(apiName, path, {
        headers,
      });

      toaster('Invite re-sent successfully');
      const payload = {
        event: 'Invite Resent',
        userId: profileInfo?.id,
        enterpriseId: profileInfo?.enterpriseID,
        originalTimestamp: dayjs().toISOString(),
        sentAt: dayjs().toISOString(),
        properties: {
          invitationId: record?.key,
          inviterId: profileInfo?.id,
          accountName: companyInfo?.name,
        },
      };
      postEvent(payload);
    } catch (error) {
      toaster('Something went wrong', 'error');
    }
    setTableLoader(false);
  };

  /**
   * resend the Bulk invite
   *
   * @param {*} record
   */
  const bulkResendInvites = async (records) => {
    if (!records) {
      toaster('Something went wrong', 'error');
      return;
    }
    setTableLoader(true);
    try {
      const apiName = 'enterpriseappinviteuser';
      const headers = await getHeaders();
      const promises = records.map((invitationId) => {
        const path = `/invite/${invitationId}/resend-invite`;
        return API.post(apiName, path, {
          headers,
        });
      });
      const inviteResendResponse = await Promise.allSettled(promises);
      const responseArray = [];
      inviteResendResponse.forEach((response, i) => {
        if (response.status === 'fulfilled') {
          const payload = {
            event: 'Invite Resent',
            userId: profileInfo?.id,
            enterpriseId: profileInfo?.enterpriseID,
            originalTimestamp: dayjs().toISOString(),
            sentAt: dayjs().toISOString(),
            properties: {
              invitationId: records[i],
              inviterId: profileInfo?.id,
              accountName: companyInfo?.name,
            },
          };
          responseArray.push(postEvent(payload));
        }
      });
      await Promise.all(responseArray);
      if (records.length !== responseArray.length) {
        toaster('Error in resending Invite', 'error');
      } else {
        toaster('Invite resent successfully');
      }
    } catch (error) {
      toaster('Something went wrong', 'error');
    }
    setTableLoader(false);
  };

  /**
   * handler on deleting the invite
   *
   * @param {*} inviteDetails
   */
  const onDeleteUser = async (inviteDetails) => {
    setShowDeleteConfirmation(false);
    setTableLoader(true);
    try {
      const apiName = 'enterpriseappinviteuser';
      const path = `/invite?id=${inviteDetails?.key}`;
      const headers = await getHeaders();
      const deleteResponse = await API.del(apiName, path, {
        headers,
      });
      if (deleteResponse) {
        const { pendingInvitations } = await fetchAllInvitedUsers(inviteDetails.enterpriseId);
        const sortedData = sortInvitedUsers(pendingInvitations);
        setTableData(sortedData);
        await licenseCountHandler();
        toaster('Invite deleted successfully');
        const payload = {
          event: 'Invite Removed',
          userId: profileInfo?.id,
          enterpriseId: profileInfo?.enterpriseID,
          originalTimestamp: dayjs().toISOString(),
          sentAt: dayjs().toISOString(),
          properties: {
            invitationId: inviteDetails?.key,
            inviterId: profileInfo?.id,
            accountName: companyInfo?.name,
          },
        };
        postEvent(payload);
      }
    } catch (error) {
      toaster('Error in deleting invite', 'error');
    }
    setTableLoader(false);
  };

  const renderDate = (date) => {
    renderDate.propTypes = {
      date: PropTypes.string.isRequired,
    };
    const splitDate = date.split('/');
    const formattedDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
    return <p className={invitesTabStyles.invitedOn}>{formattedDate}</p>;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name) => (<p className={invitesTabStyles.userName}>{name || ''}</p>),
      width: 130,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email) => (<p className={invitesTabStyles.email}>{email || ''}</p>),
      width: 130,
    },
    {
      title: 'Invited On',
      dataIndex: 'invitedOn',
      render: renderDate,
      width: 130,
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      render: (_, record) => (
        <div className={invitesTabStyles.CTAGroup}>
          <button
            id="admin-accounts-invites-resend-invite"
            type="submit"
            className={invitesTabStyles.resend}
            onClick={() => resendInvites(record)}
          >
            Resend
          </button>
          <button
            id="admin-accounts-invites-delete-invite"
            type="submit"
            className={`${invitesTabStyles.deleteButton} library-shaw-icon-new`}
            onClick={() => {
              setSelectedRow(record);
              setShowDeleteConfirmation(true);
            }}
          >
            <i className="icon-new-Remove-bin" />
          </button>
        </div>
      ),
      width: 130,
    },
  ];

  const prepareHeaderForCSV = () => {
    const headers = [];
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.dataIndex !== 'action') {
        headers.push({
          label: column.title,
          key: column.dataIndex,
        });
      }
    }
    return headers;
  };

  const passUploadedArrayFromFile = (users) => {
    setFileDataArray([...users]);
  };

  const jsonToCSV = (csvData) => {
    const items = csvData;
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','), // header row first
      ...items.map((row) => header.map((fieldName) => (row[fieldName])).join(',')),
    ].join('\r\n');
    return csv;
  };

  const closeMoreInfoBanner = () => {
    setShowMoreInfo(MoreInfoStatus.DEFAULT);
  };

  const openAddUserPopup = () => {
    setIsModalVisible(true);
  };

  const closeImportedUserProgressModal = async () => {
    setIsImportStatusModalVisible(false);
    setTableLoader(true);
    try {
      const { pendingInvitations } = await fetchAllInvitedUsers(companyInfo?.id);
      await licenseCountHandler();

      const sortedData = sortInvitedUsers(pendingInvitations);
      setTableData(sortedData);
    } catch (error) {
      console.log(error);
    }

    setTableLoader(false);
  };

  const closeAddUserModal = () => {
    setIsModalVisible(false);
    setShowInviteUserModal(false);
  };

  useEffect(() => {
    if (identityProviderClient) {
      licenseCountHandler();
    }
  }, [identityProviderClient]);

  useEffect(() => {
    if (profileInfo?.id) {
      fetchSignedInUserDetails();
    }
  }, [profileInfo]);

  useEffect(() => {
    if (showInviteUserModal) {
      setIsModalVisible(true);
    }
  }, [showInviteUserModal]);

  const invitesLimited = hasInviteLimits(companyInfo?.subscription);

  return (
    <div>
      {showMoreInfo === MoreInfoStatus.SECONDARY
      && (
      <MoreInfo
        text="<p>Here you can send invites via an individual or bulk upload and monitor invites which have not been accepted yet.</p>"
        mainContent={false}
        hideBanner={closeMoreInfoBanner}
      />
      )}
      {
      invitesLimited && licenseCountObject?.available < 0
      && (
      <div className={invitesTabStyles.licenseExpiredBanner}>
        <img
          src={warningIcon}
          alt="limit exceeded"
          className={invitesTabStyles.warningIcon}
        />
        <div>
          <h1>You have exceeded your license limit. Please do one of the following</h1>
          <ol>
            <li>Delete the extra pending invite</li>
            <li>Deactivate the extra user</li>
            <li>Contact your account manager and increase your license count </li>
          </ol>
        </div>
      </div>
      )
      }
      <LicenseInfo
        licenseDetails={licenseCountObject}
        openAddUserPopup={openAddUserPopup}
        setLicenseExceeded={setLicenseExceeded}
      />
      <div className={invitesTabStyles.invitesSentWrapper}>
        <p className={invitesTabStyles.title}>Pending Invitations</p>
        <TableComponent
          globalSearchPlaceholder="Search by learner name, email"
          displayColumns={columns}
          allData={tableData}
          tableLoader={tableLoader}
          headers={prepareHeaderForCSV()}
          fileName="Invitation Report.csv"
          bulkResend={
            {
              resendInvite:
              bulkResendInvites,
            }
          }
          domIDs={{
            Download: 'admin-accounts-invites-download',
            BulkResend: 'admin-accounts-invites-bulk-resend-invite',
          }}
        />
      </div>
      <ConfirmPopup
        onOk={() => onDeleteUser(selectedRow)}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        modalObject={{
          isVisible: showDeleteConfirmation,
          title: 'Delete Invite',
          description: `Are you sure you want to delete the invite for ${selectedRow?.email}?`,
          buttonText: 'Delete',
          selectedUser: selectedRow,
        }}
        domIDs={{
          OK: 'admin-accounts-invites-delete-invite-popup-delete',
          Cancel: 'admin-accounts-invites-delete-invite-popup-cancel',
        }}
      />
      <AddUser
        isModalVisible={isModalVisible}
        onOk={sendInvite}
        onCancel={closeAddUserModal}
        setIsImportStatusModalVisible={setIsImportStatusModalVisible}
        identityProviderClient={identityProviderClient}
        passUploadedArrayFromFile={passUploadedArrayFromFile}
        companyInfo={companyInfo}
        licenseCountObject={licenseCountObject}
        domIDs={{
          GSuite: 'admin-accounts-invites-invite-popup-gsuite',
          SendInvite: 'admin-accounts-invites-invite-popup-send-invite',
          UploadCSV: 'admin-accounts-invites-invite-popup-upload-csv',
          Invite: 'admin-accounts-invites-google-contacts-popup-invite',
          DownloadSampleCSV: 'admin-accounts-invites-invite-popup-download-sample-csv',
        }}
      />
      <ImportedUserProgress
        isImportStatusModalVisible={isImportStatusModalVisible}
        onCancel={closeImportedUserProgressModal}
        identityProviderClient={identityProviderClient}
        fileDataArray={fileDataArray}
        jsonToCSV={jsonToCSV}
        domID="admin-accounts-invites-csv-upload-progress-popup-download-failed-records"
      />
      <BuyMoreLicense
        profileInfo={profileInfo}
        isModalVisible={licenseExceeded}
        onCancel={() => setLicenseExceeded(false)}
      />
    </div>
  );
}
InviteTabView.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
  showInviteUserModal: PropTypes.bool.isRequired,
  setShowInviteUserModal: PropTypes.func.isRequired,
};
export default InviteTabView;
