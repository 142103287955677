// extracted by mini-css-extract-plugin
export var heading = "InviteTab-module--heading--2QAu5";
export var importLogs = "InviteTab-module--importLogs--3as2q";
export var downloadLink = "InviteTab-module--downloadLink--3pS1b";
export var title = "InviteTab-module--title--1djIz";
export var searchBoxWrapper = "InviteTab-module--searchBoxWrapper--2sgkr";
export var licenseExpiredBanner = "InviteTab-module--licenseExpiredBanner--3PtCs";
export var warningIcon = "InviteTab-module--warningIcon--3VgHX";
export var invitesSentWrapper = "InviteTab-module--invitesSentWrapper--KMeCK";
export var table = "InviteTab-module--table--3aX_S";
export var deleteButton = "InviteTab-module--deleteButton--18LdA";
export var resend = "InviteTab-module--resend--1IGia";
export var antCheckboxInner = "InviteTab-module--ant-checkbox-inner--2nnHb";
export var userName = "InviteTab-module--userName--21Xoc";
export var email = "InviteTab-module--email--1apK0";
export var invitedOn = "InviteTab-module--invitedOn--2lLWG";
export var CTAGroup = "InviteTab-module--CTAGroup--15ioW";