import { Spin } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import AdminContext from '../../../context/Admin/AdminContext';
import { hasInviteLimits } from '../../../services/utils';
import * as styles from '../../../styles/admin/LicenseInfo.module.scss';

export interface LicenseDetails {
  accepted: number,
  total: number,
  invited: number,
  pending: number,
  available: number,
}

interface Props {
  licenseDetails: LicenseDetails,
  openAddUserPopup: () => void,
  setLicenseExceeded: React.Dispatch<React.SetStateAction<boolean>>
}

const LicenseInfo:React.FC<Props> = ({ licenseDetails, openAddUserPopup, setLicenseExceeded }) => {
  const { companyInfo } = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);

  const invitesLimited = hasInviteLimits(companyInfo?.subscription);
  const licenseExhausted = licenseDetails?.available <= 0;

  const addUsersHandler = () => {
    if (invitesLimited && licenseExhausted) {
      setLicenseExceeded(true);
    } else {
      openAddUserPopup();
    }
  };

  useEffect(() => {
    if (companyInfo?.id && Object.keys(licenseDetails)?.length > 0) {
      setIsLoading(false);
    }
  }, [companyInfo, licenseDetails]);

  return (
    <div className={styles.licenseInfoContainer}>
      <div className={styles.licenseInfoBox}>
        {!isLoading && (
        <div className={styles.licenseAvailable}>
          {(invitesLimited)
            ? (
              <>
                <h5>{licenseDetails?.total || 0}</h5>
                <p>Licenses available</p>
                {/* <button type="button" onClick={buyLicenseHandler}>Buy More</button> */}
              </>
            )
            : (
              <>
                <h5>{licenseDetails?.invited}</h5>
                <p>Invites sent</p>
              </>
            )}
        </div>
        )}
        {!isLoading && (
        <div className={styles.rightSection}>
          <div className={styles.otherBox}>
            <h5>{licenseDetails?.accepted || 0}</h5>
            <p>Accepted</p>
          </div>
          <div className={styles.otherBox}>
            <h5>{licenseDetails?.pending || 0}</h5>
            <p>Pending</p>
          </div>
          {(invitesLimited)
           && (
           <div className={styles.otherBox}>
             <h5>{licenseDetails?.available || 0}</h5>
             <p>Unused</p>
           </div>
           )}
          <div className={styles.ctaWrapper}>
            <button
              id="admin-accounts-invites-add-new-learners"
              type="button"
              className={styles.addNewUserCta}
              onClick={addUsersHandler}
            >
              + Add New Learners
            </button>
            {licenseExhausted
             && invitesLimited
            && <p>License exhausted</p>}
          </div>
        </div>
        )}
        {isLoading && <Spin />}
      </div>
    </div>
  );
};

export default LicenseInfo;
